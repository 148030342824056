"use strict";

app.factory("usageAnalyticsService", function ($rootScope, signalRService, broadcastService) {

    let analyticsDataCache = {};
    const subscriptionCallbacks = {};

    $rootScope.$on("onUsageAnalytics", function (event, data) {
        _.assign(analyticsDataCache, _(data).keyBy(o => `${o.MenuTableName}${o.MenuKey}`).value());
        _(subscriptionCallbacks).values().forEach(callback => callback("update", _.clone(data)));
    });
    $rootScope.$on("onChatHubReconnected", function () {
        signalRService.getUsageAnalytics();
    });

    return {
        /**
         * Post usage analytics through ChatHub and store in `GMS_UserStp_UsageAnalytics`.
         * @param {"SYT_CustomStoreProc"|"SYC_MenuHdr"} menuTableName - Where the MenuKey comes from.
         * @param {number} menuKey - MenuKey.
         */
        postUsageAnalytics: signalRService.postUsageAnalytics,

        /**
         * Reload usage analytics data.
         * This function will clear current analytics data cache and get all from server again.
         */
        reloadUsageAnalyticsData: function () {
            analyticsDataCache = {};
            signalRService.getUsageAnalytics();
            _(subscriptionCallbacks).values().forEach(callback => callback("reload", []));
        },

        /**
         * @typedef UsageAnalyticsData
         * @property {"SYT_CustomStoreProc"|"SYC_MenuHdr"} MenuTableName
         * @property {number} MenuKey
         * @property {number} LastOpen - Unix timestamp, this menu was last opened at.
         * @property {number} TotalOpenCount - Total times this menu was opened.
         * @property {number} UserKey
         */

        /**
         * Callback for usage analytics data.
         *
         * @callback OnUsageAnalyticsData
         * @param {"update"|"reload"} action - Action to be performed on the data.
         * @param {UsageAnalyticsData[]} data - List of usage analytics data, empty if action is `reset`.
         */

        /**
         * Subscribe to usage analytics data.
         * @param {object} $scope - Scope of the controller.
         * @param {OnUsageAnalyticsData} callback - Callback function for analytics data.
         * @returns {_UsageAnalyticsServiceSubscription} - Subscription instance.
         */
        subscribeUsageAnalyticsData: function ($scope, callback) {
            if (!_.isFunction(callback)) {
                throw new Error("Callback must be a function.");
            }

            const uniqueId = broadcastService.guid();
            subscriptionCallbacks[uniqueId] = callback;
            $scope.$on("$destroy", function () {
                _.unset(subscriptionCallbacks, uniqueId);
            });
            callback("update", _(analyticsDataCache).values().clone());
            return new _UsageAnalyticsServiceSubscription(uniqueId, () => {
                _.unset(subscriptionCallbacks, uniqueId);
            });
        },
    };
});

class _UsageAnalyticsServiceSubscription {
    constructor(subscriptionId, unsubscribeCallback) {
        this._subscriptionId = subscriptionId;
        this._unsubscribeCallback = unsubscribeCallback;
    }

    /**
     * @returns {string} - Subscription id.
     */
    get subscriptionId() {
        return this._subscriptionId;
    }

    /**
     * Unsubscribe the subscription.
     */
    unsubscribe() {
        this._unsubscribeCallback();
    }
}

